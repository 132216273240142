/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/layout.css"

import React from "react"
import { AuthProvider } from "./src/context/userContext"

export const wrapRootElement = ({ element }) => {
  // Initialize the data layer for Google Tag Manager (this should mandatorily be done before the Cookie Solution is loaded)

  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    // Default consent mode is "denied" for both ads and analytics, but delay for 2 seconds until the Cookie Solution is loaded
    gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      wait_for_update: 2000, // milliseconds
    })
  }

  return <AuthProvider>{element}</AuthProvider>
}